import Web3 from "web3";
import Vue from 'vue';
import Ethers from './Ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
const { formatBytes32String, parseBytes32String } = require("ethers/lib/utils");

import Web3Modal from "web3modal";

import Parse from './Parse';
import Observer from "./Observer";
import store from "../store/index";
import {E_ACCOUNT_CHANGED, E_LOGIN, E_REJECT_SIGN, E_NEW_BLOCK, E_CHAIN_CHANGED} from "../constants/events";
import Config from "../config";
import Utils from "./Utils";

import ABI from "../constants/abi";

class Web3Helper {
  constructor() {
    
    this.mainFeeWallet = '0x2236927d4133C5ACFB9b093Cdda32BfF666E0219';
    this.mainAirdropWallet = '0xb5DAE5585c2Ae8eAeE2ee62352440bE288F84B0D';
    this.mainFeeAmount = 0.005 * 10 ** 18;
    
    this.baseFeeWallet = '0x2236927d4133C5ACFB9b093Cdda32BfF666E0219';
    this.baseAirdropWallet = '0xb5DAE5585c2Ae8eAeE2ee62352440bE288F84B0D';
    this.basefeeAmount = 0.005 * 10 ** 18;

    this.web3 = null;
    this.account = {};
    this.address = null;
    this.balance = 0;
    this.blockNumber = 0;
    this.timer = null;
    this.provider = null;
    this.subscription = null;
    this.chainId = null;
    this.sniperBalance = Utils.formatBigInt(0);
    this.sniperEthValue = Utils.formatBigInt(0);

    this.contracts = {};
    this.abis = {};
  }

  createAccount() {
    return this.web3.eth.accounts.create();
  }

  isTron() {
    return false;
  }

  getLevel() {
    const whitelists = [
    ];

    const copyTradingWhitelists = [
    ];
    
    const funcs = {};
    funcs.isWhitelisted = () => whitelists.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    funcs.isCopyTradingWhitelisted = () => copyTradingWhitelists.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    funcs.isAdmin = this.isAdmin;
    funcs.isTop = this.isTop;
    funcs.canUseOGMEV = this.canUseOGMEV;
    funcs.canHideCA = this.canHideCA;
    funcs.canSeeHiddenCA = this.canSeeHiddenCA;
    funcs.isOwner = this.isOwner;
    funcs.canSnipe = () => {
      return true;
      // if (funcs.isWhitelisted()) {
      //   return true;
      // }
      // // eslint-disable-next-line no-undef
      // if (BigInt(this.sniperEthValue) >= BigInt(Config.MIN_SNIPER_VALUE * 10 ** 18)) {
      //   return true;
      // }
      // return false;
    }

    funcs.canCopyTrade = () => {
      return true;
      // if (funcs.isCopyTradingWhitelisted()) {
      //   return true;
      // }
      // // eslint-disable-next-line no-undef
      // if (BigInt(this.sniperEthValue) >= BigInt(Config.MIN_SNIPER_VALUE_FOR_COPY_TRADING * 10 ** 18)) {
      //   return true;
      // }
      // return false;
    }

    funcs.canUseAccount = () => {
      return true;
      // if (funcs.canSnipe()) {
      //   return true;
      // }
      // return false;
    }

    funcs.canSeeDetails = () => {
      return true;
      // if (funcs.isWhitelisted()) {
      //   return true;
      // }
      // // eslint-disable-next-line no-undef
      // if (BigInt(this.sniperBalance) > BigInt(Config.MIN_DETAILS_SNIPER_AMOUNT * 10 ** 18)) {
      //   return true;
      // }
      // return false;
    }

    funcs.canWatch = () => {
      return true;
      // if (funcs.canSnipe()) {
      //   return true;
      // }
      // return false;
    }

    funcs.canSetTpSl = () => {
      return true;
      // if (funcs.canSnipe()) {
      //   return true;
      // }
      // return false;
    }
    funcs.address = this.address;
    return funcs;
  }

  isBaseWhitelisted() {
    if (this.isTop()) {
      return true;
    }
    return true;
    // const whitelist = [
      // '0x78688ADf3D40eEa7B6D06e242a9b18e023C66428',
    //   '0x28367d2656434b928a6799e0b091045e2ee84722',
    //   '0x10695d7f71161f5bcdca9599dfe1cac8a9673d7a',
    //   '0x1fBfE1516c14CcF31eD862b5ff9A00637F4f0991',
    //   '0xa98Bf23a0c92c90ea1dA0aBBE3213f74Ad135D54',
    //   '0x19cAe3b34F1AF18d9BCC5016EC4E2F3203835Aca',
    //   '0x1607644EE87Fd3a63e5002d7AA8Fe3b788d4C29B'
    // ];
    // try {
    //   return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
    //   || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    // } catch (e) {
    //   return false;
    // }
  }

  isOwner() {
    const whitelist = [
      '0x74c1928dc232bdd618169b73fd068e310402c817',
      '0x027e3b567de6f29309f1bf1b382946285ab0758d',
      '0xdf1a7688edc70dc795025bdce7e49ed262870790',
      '0x78688adf3d40eea7b6d06e242a9b18e023c66428',
      '0xdc25868db509603e1d315c8f2ababca650c454ae',
      '0x55ff1fAA6d1906985C34a679A12ab0473313d3de', //me
      '0x42fA0D4dc302F4C81040eE32c8d58e8C8E9e1437', // me
      '0xae1131e82c86339F6b98B63ff5105C647c90179a', // me
      '0xc8a58e79a12ef0fdccec76bfc85adf877609568a', // me
      '0x0a1660A315594d321969DF704D7C19e1CBf3AF2B', // me
    ];
    try {
      return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  shouldChargeAll() {
    const whitelist = [
      // '0xDEd0e35fD38a8258c0F05b166181Dc1a568038a6', // g
      // '0x90e8987653a1A4d16f84f9D4a3b39a654201f926', // g
      // '0x7F2e5b6FEEd0F3A0b3F8afACfe6b167EEC80285C', // g

      // '0x132e24d664eea7db86546a307f90bcf2ea357656', // gbase
      // '0xfc5e40f59785f7e5aa951eab24c8faa1299bd473', // gbase
      // '0x19cae3b34f1af18d9bcc5016ec4e2f3203835aca', // gbase

      

      // '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59', //g
      // '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f', //g

      // '0x26449f7F673095e325efEeB8835DA26787B78Ac6', //g
      // '0xba8858E784f62c611b4696dD88fdB20161cf836f', //g
      // '0x5270c17F3321a8A574cE4eeEDce60318333d3601', //g
      // '0x41f4B3442085De4b8DCec20E86A8Bbe3042751E3', //g
      // '0x0A8efC712437B16e9b3a53832Ef2b5B66118EBf1', //g
      // '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59', //g
      // '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f', //g

      // '0x4856B61A1bbd9D14d899ef1d32376228B804541c', //fr      
    ];
    try {
      return whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }
  
  shouldCharge() {
    // return true;
    const whitelist = [
      // '0xded0e35fd38a8258c0f05b166181dc1a568038a6',
      // '0x90e8987653a1A4d16f84f9D4a3b39a654201f926',
      // '0x7F2e5b6FEEd0F3A0b3F8afACfe6b167EEC80285C',
      // '0x4856B61A1bbd9D14d899ef1d32376228B804541c', //fr
      // '0x28367D2656434b928a6799E0B091045e2ee84722', // d
      // '0xF653644a3C4eaaa44C8930C5A18eFFCEf2E418E3', // d
      
    ];
    try {
      return whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  shouldExclude() {
    const whitelist = [
      '0x1fBfE1516c14CcF31eD862b5ff9A00637F4f0991', // k
      '0xa98bf23a0c92c90ea1da0abbe3213f74ad135d54', // k
      '0x28367D2656434b928a6799E0B091045e2ee84722', //d
      '0xF653644a3C4eaaa44C8930C5A18eFFCEf2E418E3', //d
      '0x4c6a0cae636d7023f8a121f01f9ebdbfead86e1b', // f test
      
    ];
    try {
      return whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  canSeeHiddenCA() {
    if (this.isOwner()) {
      return true;
    }

    const whitelist = [
      '0xDEd0e35fD38a8258c0F05b166181Dc1a568038a6',
      '0x132E24d664eEA7db86546a307f90Bcf2eA357656',
      
      // gs
      '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59',
      '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f',
      '0x26449f7F673095e325efEeB8835DA26787B78Ac6',
      '0xba8858E784f62c611b4696dD88fdB20161cf836f',
      '0x5270c17F3321a8A574cE4eeEDce60318333d3601',
      '0x41f4B3442085De4b8DCec20E86A8Bbe3042751E3',
      '0x0A8efC712437B16e9b3a53832Ef2b5B66118EBf1',
      '0x84768d638b456abDaFbC123093750382B4a38409'
    ];
    try {
      return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  canHideCA() {
    if (this.isTop()) {
      return true;
    }

    const whitelist = [
      '0x4856b61a1bbd9d14d899ef1d32376228b804541c',
      '0x1607644ee87fd3a63e5002d7aa8fe3b788d4c29b',
      '0x84768d638b456abDaFbC123093750382B4a38409'
    ];
    try {
      return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  canSeeContracts() {
    if (this.isTop()) {
      return true;
    }

    const whitelist = [
      // "0x28367d2656434b928a6799e0b091045e2ee84722", //d
      '0x4856b61a1bbd9d14d899ef1d32376228b804541c',
      '0x1607644ee87fd3a63e5002d7aa8fe3b788d4c29b',
    ];
    try {
      return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  canUseOGMEV() {
    return true;
    // if (this.isTop()) {
    //   return true;
    // }
    // const whitelist = [
    // ];
    // try {
    //   return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
    //   || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    // } catch (e) {
    //   return false;
    // }
  }

  canUseDapp(connected) {
    const whitelist = [
      '0xDEd0e35fD38a8258c0F05b166181Dc1a568038a6', // g
      '0x90e8987653a1A4d16f84f9D4a3b39a654201f926', // g
      '0x7F2e5b6FEEd0F3A0b3F8afACfe6b167EEC80285C', // g
      '0x8228d3c3eF58dAf32F54eE52eE7C370CAb1d3389', // g

      '0x132e24d664eea7db86546a307f90bcf2ea357656', // gbase
      '0xfc5e40f59785f7e5aa951eab24c8faa1299bd473', // gbase
      '0x19cae3b34f1af18d9bcc5016ec4e2f3203835aca', // gbase

      '0x28367D2656434b928a6799E0B091045e2ee84722', //d
      '0xF653644a3C4eaaa44C8930C5A18eFFCEf2E418E3', //d
      '0x10695d7F71161f5BCDcA9599dFE1CAc8A9673D7A', //d

      '0xa98bf23a0c92c90ea1da0abbe3213f74ad135d54', // k
      '0x1fBfE1516c14CcF31eD862b5ff9A00637F4f0991', //k

      '0x4c6a0cae636d7023f8a121f01f9ebdbfead86e1b', // f test
      '0x4856B61A1bbd9D14d899ef1d32376228B804541c', // fr
      '0x1607644EE87Fd3a63e5002d7AA8Fe3b788d4C29B', // fr
      '0x7C10310DEDe49496646b3833bd4F8977b78A7a76', //fr

      '0x027e3b567DE6f29309F1bF1b382946285Ab0758d', // o
      '0x74c1928dc232bdd618169b73fd068e310402c817', // o
      '0xdc25868db509603e1d315c8f2ababca650c454ae', // o
      '0x78688adf3d40eea7b6d06e242a9b18e023c66428', // o 
      '0xc8a58E79A12EF0FDCceC76bfc85Adf877609568A', // o

      '0x5D653641416b49A829a45112e1c2301C3992B0d6', // so

      // '0xAe26238D9d7f0810c5ed7060F9CF0C8AeAbb4a13', // fly
      '0xAfBF3a68cCe0Bc90147B17bE177BA4771fc2569B', //fly
      '0xAfBF3a68cCe0Bc90147B17bE177BA4771fc2569B',
      '0x65AA923F71384D373aD7521b854863Bc90d1b261',
      '0x463A4a8a17Eed7148C2ef0C915B2E07Ec1e501F4',
      '0x9c3c2915a944e0E25ddc7d8ac326d5A56a4f4340',
      '0x28F7943a91E44815E4e894843813EB67655A4faC',

      '0x7E4Ae47E849a91518C715d14ecf165593965678b', //k
      '0xe8B95AF77302c7D06ca96f4E954eb8018B3A1eb8', //k

      '0xa8Cba21aC2123fc5D6b1f46640C68721D4E2C8D4', // d
      '0x52d260f3ccEC1AB2d4392bEac2f3cb57959D68a0',// d
      '0xb3649DdCdcaFcD5623e02c3c8ce513f1e8f1f423',// d

      '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59', //g
      '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f', //g

      '0x26449f7F673095e325efEeB8835DA26787B78Ac6', //g
      '0xba8858E784f62c611b4696dD88fdB20161cf836f', //g
      '0x5270c17F3321a8A574cE4eeEDce60318333d3601', //g
      '0x41f4B3442085De4b8DCec20E86A8Bbe3042751E3', //g
      '0x0A8efC712437B16e9b3a53832Ef2b5B66118EBf1', //g
      '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59', //g
      '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f', //g

      '0x7432294d9E1e8fEA2553E9294588b537e779580d', //fr
      '0xBEbE55225794F3A6D45bE1a1Ed53E17E83FC1969', //fr

      '0x0B26C4BC5167DD651Ca57BFA748f9cC9fF0EE0fd', // sol
      '0xEE4eE92f05c81c17997DFA8cc2004B2160247aF1', // d

      '0x55ff1fAA6d1906985C34a679A12ab0473313d3de', //me
      '0x84768d638b456abDaFbC123093750382B4a38409',

      '0xe59410F5ab13058886fD76023aA20EdeECFba39b', // hecto

      '0x89C83657AB99303568053Eb37A573D5D8c07c018',

      '0x24772315C44D81440665aF49099b5258E36919B4',

      '0x42fA0D4dc302F4C81040eE32c8d58e8C8E9e1437', // me bb
      '0xae1131e82c86339F6b98B63ff5105C647c90179a', // me
      '0x0a1660A315594d321969DF704D7C19e1CBf3AF2B', // me

      '0x9E2F49D3c4FB9ABeAd591034D787508Fa3C88f59', //ghost

      '0xD3d8B09eaC79744cEAfcE8517728aEAe1E3cC117', // temp

      '0x3c7Bc57675B236F7CC93fb29F7d527E5BCf29e0D', // hetape
      '0x1e047A6717Bfe0215405886281031A091b71C8e8', // g
      '0x4F89D418eFd667A2Db649E8540CE4ad1dA93796a', // g

      '0x006a9e609E8cBEfAbCE911315666920745C13A6c',
      '0x5D7f01A7D301c3693eB4E5001907c140d65a12b1',
      '0x06b2134fb59a6442A09D0d2a04980f2CE17A020A',
      '0x3c7Bc57675B236F7CC93fb29F7d527E5BCf29e0D'
    ];
    try {
      return connected.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(connected.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  isTop() {
    
    if (this.isOwner()) {
      return true;
    }
    const whitelist = [
      '0x027e3b567de6f29309f1bf1b382946285ab0758d',
      '0x74c1928dc232bdd618169b73fd068e310402c817',
      // '0xdc25868db509603e1d315c8f2ababca650c454ae',
      '0x6e1792De826276F78BC7ef984c8A6102AA908264',
      '0xc8a58e79a12ef0fdccec76bfc85adf877609568a',
      '0xc385d60DF28a01c89a8e3d1c3230d93C3b14706b',
      '0x3e5f1e7b62960e7945d68b5933c2ab31a344a5c6',
      '0xDF1a7688EDC70DC795025bDce7e49eD262870790',
      '0xd39f8920685304135ee53d73a1cfa8003c58eec3',
      '0xDEd0e35fD38a8258c0F05b166181Dc1a568038a6',
      '0xe86779d5ca9A97B882e6F4f8de2CaAB7dE467ecB',
      '0x7F2e5b6FEEd0F3A0b3F8afACfe6b167EEC80285C',
      '0x90e8987653a1A4d16f84f9D4a3b39a654201f926',
      '0x78688adf3d40eea7b6d06e242a9b18e023c66428',
      '0x132E24d664eEA7db86546a307f90Bcf2eA357656',
      '0x7F2e5b6FEEd0F3A0b3F8afACfe6b167EEC80285C',
      '0xfc5E40f59785f7e5aa951eab24c8fAA1299BD473',
      // '0x4856b61a1bbd9d14d899ef1d32376228b804541c', // fr
      // '0x1607644ee87fd3a63e5002d7aa8fe3b788d4c29b', // fr
      '0x8228d3c3eF58dAf32F54eE52eE7C370CAb1d3389', //g
      '0xAe26238D9d7f0810c5ed7060F9CF0C8AeAbb4a13', // fly
      
      '0xAfBF3a68cCe0Bc90147B17bE177BA4771fc2569B', //fly
      '0x65AA923F71384D373aD7521b854863Bc90d1b261',
      '0x463A4a8a17Eed7148C2ef0C915B2E07Ec1e501F4',
      '0x9c3c2915a944e0E25ddc7d8ac326d5A56a4f4340',
      '0x28F7943a91E44815E4e894843813EB67655A4faC',

      // gs
      '0x83219104cA01954F0E4Cb07d6414b70ad2EF4E59',
      '0x39ec11cD431a1B29d756b75cbcfda5FF1A8C084f',
      '0x26449f7F673095e325efEeB8835DA26787B78Ac6',
      '0xba8858E784f62c611b4696dD88fdB20161cf836f',
      '0x5270c17F3321a8A574cE4eeEDce60318333d3601',
      '0x41f4B3442085De4b8DCec20E86A8Bbe3042751E3',
      '0x0A8efC712437B16e9b3a53832Ef2b5B66118EBf1',
      '0x84768d638b456abDaFbC123093750382B4a38409',

      
      

      '0x7432294d9e1e8fea2553e9294588b537e779580d', //fr
      '0xbebe55225794f3a6d45be1a1ed53e17e83fc1969',// fr

      '0x1e047A6717Bfe0215405886281031A091b71C8e8', // g
      '0x4F89D418eFd667A2Db649E8540CE4ad1dA93796a', // g

      '0x006a9e609E8cBEfAbCE911315666920745C13A6c',
      '0x5D7f01A7D301c3693eB4E5001907c140d65a12b1',
      '0x06b2134fb59a6442A09D0d2a04980f2CE17A020A',
      '0x3c7Bc57675B236F7CC93fb29F7d527E5BCf29e0D'
    ];
    try {
      return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
      || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    } catch (e) {
      return false;
    }
  }

  isAdmin() {
    return true;
    // if (this.isTop()) {
    //   return true;
    // }
    // const whitelist = [
    //   '0x865d4401f09846a53d0f4740361863aa0fd44045',
    //   '0x847794acd5b43287140d16e84820b2df4b7f732e',
    //   '0x5d653641416b49a829a45112e1c2301c3992b0d6',
    //   '0x1607644ee87fd3a63e5002d7aa8fe3b788d4c29b'
    // ];
    // try {
    //   return this.address.toLowerCase() == Config.ADMIN.toLowerCase()
    //   || whitelist.map(address => address.toLowerCase()).includes(this.address.toLowerCase());
    // } catch (e) {
    //   return false;
    // }
  }

  async init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: Config.INFURA_ID, // required
          rpc: {
            [Config.CHAIN_ID]: Config.RPC_URL
          },
        },
      }    
    };

    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions // required
    });

    const provider = await web3Modal.connect();

    this.provider = provider;
    this.web3 = new Web3(provider);

    this.initObserver();
    
    const accounts = await this.web3.eth.getAccounts();

    const connectedAccount = accounts[0];
    if (!this.canUseDapp(connectedAccount)) {
      throw new Error('Not whitelisted');
    }

    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      // console.log('Account changed', accounts);
      Observer.$emit(E_ACCOUNT_CHANGED, accounts);
    });
    
    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      // console.log('Chain Changed', chainId);
      // eslint-disable-next-line no-undef
      // if (BigInt(chainId) != BigInt(Config.CHAIN_ID)) {
      //   this.switchNetwork();
      // }

      window.location.reload();
      this.chainId = parseInt(chainId);
      Observer.$emit(E_CHAIN_CHANGED, accounts);
    });
    
    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log('Chain Connected', info.chainId);
    });
    
    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log('Wallet Disconnected', error.code, error.message);
    });

    Observer.$emit(E_ACCOUNT_CHANGED, accounts);
  }

  async switchNetwork() {
    // eslint-disable-next-line no-undef
    const chainId = '0x' + BigInt(Config.CHAIN_ID).toString(16);
    if (this.isNetworkRequest) {
      return;
    }
    this.isNetworkRequest = true;
    try {
      await this.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }],
      });
    } catch (switchError) {
      // console.log('Switch Network Error', switchError);
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await this.provider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId,
              // chainName: Config.CHAIN_NAME,
              // nativeCurrency: {
              //   name: 'Bunny Chain',
              //   symbol: 'BNC', // 2-6 characters long
              //   decimals: 18
              // },
              // blockExplorerUrls: ['https://explorer.bunnychain.network'],
              // rpcUrls: [Config.RPC_URL],
            }],
          });
        } catch (addError) {
          // handle "add" error
          // console.log('Add Network Error', addError);
        }
      }
      // handle other "switch" errors
    }
    this.isNetworkRequest = false;
  }

  initObserver() {
    Observer.$off(E_ACCOUNT_CHANGED);

    Observer.$on(E_ACCOUNT_CHANGED, async (accounts) => {
      this.address = accounts[0];
      this.chainId = parseInt(await this.web3.eth.net.getId())

      try {
        const signature = await this.web3.eth.personal.sign('insidor_dapp', accounts[0]);
        this.signature = signature;
      } catch (e) {
        console.log(e);
        Observer.$emit(E_REJECT_SIGN);
        return;
      }

      this.balance = 0;
      await this.initAccount();
    })

    // Observer.$on(E_LOGIN, (account) => {
    //   this.account = account;
    //   this.address = account.get('address');
    //   this.balance = 0;
    //   Parse.getUserClass().logIn(this.address, Config.PARSE_DEFAULT_PASSWORD);
    //   store.commit('SET', ['account', account]);
    //   this.initTimer();
    // })
  }

  initTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    const handler = () => {
      this.updateBalance();
    };
    handler();
    // TODO: call setInterval if needed
    // this.timer = setInterval(handler, 5000);
  }

  async initAccount() {
    // localStorage.clear();
    localStorage.removeItem('Parse/sniper/currentUser');
    localStorage.removeItem('Parse/sniper/installationId');
    Parse.init(true);
    console.log('initing ethers');
    await Ethers.init();
    // console.log('Init Account', this.address);
    const User = Parse.getUserClass();
    const query = Parse.getQuery(User);
    query.equalTo('address', this.address.toLowerCase());
    let user = await query.find();
    if (user.length == 0) {
      user = new User({
        address: this.address.toLowerCase(),
        username: this.address.toLowerCase(),
        password: Config.PARSE_DEFAULT_PASSWORD
      });
      await user.save();
    } else {
      user = user[0];
    }

    // Subscribe to new blocks
    this.web3.eth.clearSubscriptions();
    this.subscription = this.web3.eth.subscribe('newBlockHeaders', function(error, result){
      if (!error) {
        this.blockNumber = result.number;
        Observer.$emit(E_NEW_BLOCK, result.number);
        Observer.$emit('newblockupdate', result.number);
        Observer.$emit('newblockpositionupdate', result.number);
        Observer.$emit('newblockaccountupdate', result.number);
        return;
      }
      console.error(error);
    })
    .on("connected", function(subscriptionId){
      console.log('connected', subscriptionId);
    })
    .on("data", function(blockHeader){
      console.log('block data', blockHeader);
    })
    .on("error", console.error);
    
    // unsubscribes the subscription
    this.subscription.unsubscribe(function(error, success){
      if (success) {
        console.log('Successfully unsubscribed!');
      }
    });  

    // Moved from E_LOGIN
    this.account = user;
    this.address = user.get('address');
    this.balance = 0;

    await Parse.getUserClass().logIn(this.address, Config.PARSE_DEFAULT_PASSWORD);
    
    user.set('timestamp', this.signature);
    await user.save();

    store.commit('SET', ['account', user]);
    user.set('name', 'Wallet');
    Vue.set(user, 'balance', 0);
    this.initTimer();

    Observer.$emit(E_LOGIN, user);
  }

  bytes32(str) {
    return formatBytes32String(str);
  }

  parseBytes32(bytes) {
    return parseBytes32String(bytes);
  }

  isAddress(address) {
    return this.web3.utils.isAddress(address);
  }

  getNetwork() {
    const networks = {
      1: {
        network: 'main',
        title: 'ETH',
        asset: 'ETH'.toLowerCase(),
        currency: 'ETH',
        explorer: 'https://etherscan.io/',
        dextool: 'https://www.dextools.io/app/ether/pair-explorer/'
      },
      4: {
        network: 'rinkeby',
        title: 'RINKEBY',
        asset: 'ETH'.toLowerCase(),
        currency: 'ETH',
        explorer: 'https://rinkeby.etherscan.io/',
        dextool: 'https://www.dextools.io/app/ether/pair-explorer/'
      },
      8453: {
        network: 'base',
        title: 'BASE',
        asset: 'ETH'.toLowerCase(),
        currency: 'ETH',
        explorer: 'https://basescan.org/',
        dextool: 'https://www.dextools.io/app/base/pair-explorer/'
      },
      56: {
        network: 'bsc-main',
        title: 'BSC',
        asset: '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
        currency: 'BNB',
        explorer: 'https://bscscan.com/',
        dextool: 'https://www.dextools.io/app/bsc/pair-explorer/'
      },
      25: {
        network: null,
        cantWatch: true,
        title: 'CRO',
        currency: 'CRO',
        explorer: 'https://cronos.org/explorer',
        dextool: 'https://dexscreener.com/cronos/'
      }
    }
    if (this.chainId == 8453) {
      if (!this.isBaseWhitelisted()) {
        return null;
      }
    }
    // console.log(this.chainId);
    return networks[this.chainId];
  }

  async getBalance(address) {
    // console.log(address);
    return this.web3.eth.getBalance(address);
  }

  async getTokenBalance(token, address) {
    const contractHandler = await this.getTokenContract(token);
    const balance = await contractHandler.methods.balanceOf(address).call();
    return balance;
  }

  async getSniperBalance() {
    const web3 = new Web3(new Web3.helper().providers.HttpProvider(Config.MAINNET_RPC));
    const sniper = new web3.eth.Contract(ABI.TokenABI, Config.SNIPER_ADDRESS);
    const balance = await sniper.methods.balanceOf(this.address).call();
    
    if (balance == 0) {
      return [0, 0];
    }
    const router = new web3.eth.Contract(ABI.UniswapRouterABI, Config.MAINNET_UNI_ROUTER_ADDRESS);
    const amountOut = await router.methods.getAmountsOut(balance, [
      Config.SNIPER_ADDRESS,
      Config.MAINNET_WETH_ADDRESS
    ]).call()
    return [balance, amountOut[1]];
  }

  async getEscrowBalance(address) {
    if (!address) {
      address = this.address;
    }
    const contract = this.getEscrowContract();
    try {
      return await contract.methods.balanceOf(address).call();
    } catch (e) {
      return 0;
    }
  }

  async updateBalance() {
    // const chainId = await this.web3.eth.net.getId()
    // eslint-disable-next-line no-undef
    // if (BigInt(chainId) != BigInt(Config.CHAIN_ID)) {
    //   await this.switchNetwork();
    //   console.log('network switch');
    //   return;
    // }

    // const tokenContract = await this.getTokenContract();
    const balance = await this.web3.eth.getBalance(this.address);
    // const balance = await tokenContract.methods.balanceOf(this.address).call();
    this.balance = balance;
    store.commit('SET', ['balance', Utils.formatBalance(balance)]);
  }

  sign(address, nonce) {
    const message = this.web3.eth.accounts.hashMessage(`I am signing my one-time nonce: ${nonce}`);
    return this.web3.eth.sign(message, address);
  }

  getAbi(address) {
    return this.abis[address];
  }

  setAbi(address, abi) {
    return this.abis[address] = abi;
  }

  async getTokenContract(address) {
    if (!address) {
      address = Config.SNIPER_ADDRESS;
    }
    return new this.web3.eth.Contract(ABI.TokenABI, address);
  }

  addDexList(dex) {
    const network = this.getNetwork();
    Config[`${network.title}_DEX_LIST`].push(dex);
  }

  getDexList() {
    const network = this.getNetwork();
    return Config[`${network.title}_DEX_LIST`];
  }

  getWETHAddress() {
    const network = this.getNetwork();
    return Config[`${network.title}_WETH_ADDRESS`];
  }

  getAggregatorAddress() {
    const network = this.getNetwork();
    // if (this.address.toLowerCase() == '0x027e3b567de6f29309f1bf1b382946285ab0758d'.toLowerCase()) {
    //   return '0x88fB1aA7DbC5ED7f48371DeD2656483Fd10c0B41';
    // }
    if (this.isTop()) {
      if (Config[`${network.title}_TOP_AGGREGATOR_ADDRESS`])
      return Config[`${network.title}_TOP_AGGREGATOR_ADDRESS`];
    }
    return Config[`${network.title}_AGGREGATOR_ADDRESS`];
  }

  getSellerAddress() {
    const network = this.getNetwork();
    return Config[`${network.title}_SELLER_ADDRESS`];
  }

  getEscrowAddress() {
    const network = this.getNetwork();
    return Config[`${network.title}_ESCROW_ADDRESS`];
  }

  getRouterAddress() {
    const network = this.getNetwork();
    return Config[`${network.title}_ROUTER_ADDRESS`];
  }

  getRouterV2Address() {
    const network = this.getNetwork();
    // if (this.address.toLowerCase() == '0x027e3b567de6f29309f1bf1b382946285ab0758d'.toLowerCase()) {
    //   return '0x1576b581a7AB1e8f7a4BEa784cf6f9a9438fC3dB';
    // }
    if (this.isTop()) {
      if (Config[`${network.title}_TOP_ROUTER_V2_ADDRESS`])
      return Config[`${network.title}_TOP_ROUTER_V2_ADDRESS`];
    }
    return Config[`${network.title}_ROUTER_V2_ADDRESS`];
  }

  async getEscrowContract() {
    return new this.web3.eth.Contract(ABI.EscrowABI, this.getEscrowAddress());
  }

  async getUniswapV2Contract(address) {
    return new this.web3.eth.Contract(ABI.UniswapRouterABI, address);
  }

  async getUniswapV2FactoryContract(address) {
    return new this.web3.eth.Contract(ABI.UniswapFactoryABI, address);
  }

  async getAggregatorContract() {
    return new this.web3.eth.Contract(ABI.AggregatorABI, this.getAggregatorAddress());
  }

  async getSellerContract() {
    return new this.web3.eth.Contract(ABI.SellerABI, this.getSellerAddress());
  }

  async getRouterV2Contract() {
    return new this.web3.eth.Contract(ABI.RouterV2ABI, this.getRouterV2Address());
  }

  async getRouterContract() {
    return new this.web3.eth.Contract(ABI.RouterABI, this.getRouterAddress());
  }

  async decimals(address) {
    if (!address) {
      address = Config.SNIPER_ADDRESS;
    }
    const tokenContract = await this.getTokenContract(address);
    return parseInt(await tokenContract.methods.decimals().call());
  }

  async getNonce(address) {
    return this.web3.eth.getTransactionCount(address);
  }

  async getTokenDetails(address) {
    // console.log('getting contract details');
    const contract = await this.getTokenContract(address);
    // console.log('contract', contract);
    if (this.contracts[address]) {
      return this.contracts[address];
    }

    const result = {};
    try {
      console.log('owner')
      result.owner = await contract.methods.owner().call();
      console.log(result.owner);
    } catch (e) {
      console.log(e);
      try {
        console.log('owner again')
        result.owner = await contract.methods.getOwner().call();
        console.log(result.owner);
      } catch (e) {
        console.log(e);
      }
    }
    try {
      console.log('total supply')
      result.totalSupply = await contract.methods.totalSupply().call();
      console.log(result.totalSupply);
    } catch (e) {
      console.log(e);
    }
    try {
      console.log('decimals')
      result.decimals = await contract.methods.decimals().call();
      console.log(result.decimals);
    } catch (e) {
      console.log(e);
    }
    try {
      console.log('name');
      result.name = await contract.methods.name().call();
      console.log(result.name);
    } catch (e) {
      console.log(e);
    }
    try {
      console.log('symbol');
      result.symbol = await contract.methods.symbol().call();
      console.log(result.symbol);
    } catch (e) {
      console.log(e);
    }
    this.contracts[address] = result;
    return result;
  }

  async getGasPrice() {
    return await this.web3.eth.getGasPrice();
  }

  async estimateGasLimit(option) {
    // from, to, data, value
    return await this.web3.eth.estimateGas(option)
  }

  async send(transaction, privateKey, options) {
    // from, to, gas: gasLimit, value, gasPrice / (maxFeePerGas, maxPriorityFeePerGas)
    if (transaction) {
      options.data = transaction.encodeABI();
    }
    let signedTx;
    if (privateKey) {
      signedTx = await this.web3.eth.accounts.signTransaction(options, privateKey);
    } else {
      // console.log('from', options);
      return await this.web3.eth.sendTransaction(options);
    }

    const result = await this.web3.eth.sendSignedTransaction(signedTx.rawTransaction);
    result.hash = result.blockHash;
    return result;    
  }

  callAfterBlocks(startBlock, numBlocks, callback) {
    if (numBlocks == 0) {
      callback(true);
      return;
    }

    console.log(startBlock, numBlocks, callback);

    var subscription = this.web3.eth.subscribe('newBlockHeaders', function(error){
      if (!error) {
        return;
      }

      // this.web3.eth.clearSubscriptions();
      console.error(error);
    })
    .on("connected", function(subscriptionId){
      console.log('connected');
        console.log(subscriptionId);
    })
    .on("data", function(blockHeader){
      console.log('block data', blockHeader);
    })
    .on("error", console.error);
    
    // unsubscribes the subscription
    subscription.unsubscribe(function(error, success){
      if (success) {
        console.log('Successfully unsubscribed!');
      }
    });  
  }
}

const helper = new Web3Helper();
// helper.init();
export default helper;
